.c-sfwd-courses-card__subtitle {
  font-size: 0.875rem;
  margin-bottom: -0.5rem;
}

.c-sfwd-courses-card__course-properties {
  margin-top: 8px;
  line-height: 1;

  i {
    font-size: 0.8125rem;
    line-height: 1.538;
    margin-right: 0.5rem;
  }

  span {
    font-size: 0.75rem;
    line-height: 1.67;
  }
}

.c-sfwd-courses-card__progress-bar {
  position: absolute;
  bottom: 2px;
  left: 6px;
  right: 6px;
  padding: 0;
  @include media-breakpoint-up(lg) {
    bottom: 6px;
    background: var(--color-light);
    z-index: 1;
    border-radius: 4px;
  }


  .ld-progress-steps {
    display: none;
  }

  .learndash-wrapper .ld-progress {
    margin: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
  }

  .learndash-wrapper.learndash-widget .ld-progress .ld-progress-heading {
    margin: 0;
  }

  .learndash-wrapper .ld-progress .ld-progress-heading .ld-progress-stats .ld-progress-percentage {
    white-space: nowrap;
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 2.1667;
    margin-right: 6px;
    text-transform: lowercase;
  }

  .learndash-wrapper.learndash-widget .ld-progress .ld-progress-bar {
    width: 140px;
    margin: 6px 48px 6px 6px;
    background: var(--color-tertiary-24);
    height: 10px;
    border-radius: 8px;
  }

  .learndash-wrapper .ld-progress .ld-progress-bar .ld-progress-bar-percentage {
    background: var(--color-primary);
    margin: 0;
    height: 10px;
    border-radius: 8px;
  }
}

.c-sfwd-courses-card .c-item-card__content {
  @include media-breakpoint-down(md) {
    padding-bottom: 1.4rem;
    position: relative;
    width: 100%;
  }
}

.c-item-card__image--sfwd-courses{
  //height: 100%;
}
